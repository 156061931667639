export const menuConfig = {
  esimcrew: [
    { id: "MENU001", name: "Home" },
    { id: "MENU007", name: "FAQ" },
    { id: "MENU005", name: "About Us" },
    { id: "MENU006", name: "Contact" },
  ],
  data2go: [
    { id: "MENU001", name: "Home" },
    { id: "MENU002", name: "Pricing" },
    { id: "MENU003", name: "Partners" },
    { id: "MENU004", name: "Affiliate" },
    { id: "MENU005", name: "About Us" },
    { id: "MENU006", name: "Contact" },
    { id: "MENU007", name: "FAQ" },
  ],
  esimlinks: [
    { id: "MENU001", name: "Home" },
    { id: "MENU002", name: "Pricing" },
    { id: "MENU004", name: "Affiliate" },
    { id: "MENU005", name: "About Us" },
    { id: "MENU006", name: "Contact" },
  ],
  esimple: [
    { id: "MENU001", name: "Home" },
    { id: "MENU002", name: "Pricing" },
    { id: "MENU004", name: "Affiliate" },
    { id: "MENU005", name: "About Us" },
    { id: "MENU006", name: "Contact" },
  ],
  ynertia:[
    { id: "MENU001", name: "Home" },
    { id: "MENU002", name: "Pricing" },
    { id: "MENU003", name: "Partners" },
    { id: "MENU004", name: "Affiliate" },
    { id: "MENU005", name: "About Us" },
    { id: "MENU006", name: "Contact" },
    { id: "MENU007", name: "FAQ" },
  ],
  awesim: [
    { id: "MENU001", name: "Home" },
    { id: "MENU008", name: "Global eSIMs" },
    { id: "MENU003", name: "Partners" },
    { id: "MENU005", name: "About Us" },
    { id: "MENU006", name: "Contact" },
  ],
};
export const languageConfig = {
  esimcrew: [
    { name: "Spanish", code: "es" },
    { name: "Portuguese", code: "pt" },
    { name: "Tagalog", code: "tl" },
    { name: "English", code: "en" },
  ],
  data2go: [
    { name: "Spanish", code: "es" },
    { name: "Portuguese", code: "pt" },
    { name: "English", code: "en" },
  ],
  esimple: [
    { name: "Dutch", code: "nl" },
    { name: "English", code: "en" },
  ],
  ynertia: [
    { name: "Spanish", code: "es" },
    { name: "Portuguese", code: "pt" },
    { name: "Dutch", code: "nl" },
    { name: "English", code: "en" },
    { name: "Tagalog", code: "tl" },
  ],
  awesim: [
    { name: "Spanish", code: "es" },
    { name: "Portuguese", code: "pt" },
    { name: "English", code: "en" },
  ],
};
export const pageConfig = {
    All: "All",
    aboutUs: "About us",
    affiliate: "Affiliate",
    contact: "Contact",
    faq: "FAQ",
    general: "General",
    header: "Menu",
    home: "Home",
    partner: "Partner",
    labels: "Labels",
    messageCatalog: "Toast Messages",
    myAccount: "My account",
    validations: "Form validations",
    nativeapp: "Mobile APP"
}
