import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import * as PR from "../../../prime-modules/index";
import "./LanguageTranslate.scss";
import { getLanguageTranslates, uploadTranslations } from "../../../services/api";
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from "react-redux";
import { languageConfig, pageConfig } from "../header/MenuConfig";

const LanguageTranslate = () => {
    const appName = process.env?.REACT_APP_NAME;
    const dispatch = useDispatch();
  const toast = useRef();
 
  const [translatedList, setTranslatedList] = useState([]);
  const [originalTranslatedList, setOriginalTranslatedList] = useState([]);
  const [pendingUpdates, setPendingUpdates] = useState({});
  const [languageList, setLanguageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editText, setEditText] = useState(false);
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);

  const languages = languageConfig[appName] ? languageConfig[appName] :[
    { name: "Spanish", code: "es" },
    { name: "Portuguese", code: "pt" },
    { name: "Dutch", code: "nl" },
    { name: "Tagalog", code: "tl" },
    { name: "English", code: "en" },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [filteredSectionsList, setFilteredSectionsList] = useState([]);
  const [filteredSection, setFilteredSection] = useState('All');
  const disableSections = useMemo(() => {
    return ['affiliate', 'partner']
  }, [])
const [searchText, setSearchText] = useState('');
const isBase64Image = (image) => {
  if(image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA')){
    return true
  }
  return false
}

  const getTranslations = useCallback(() => {
    setLoading(true);
    const lang = selectedLanguage.code;
    getLanguageTranslates(lang, headers, dispatch, (response) => {
      setLoading(false);
      if (response.result === "SUCCESS") {
        const list = response.data ? response.data : {};
        setTranslatedList(list);
        let dropdownOptions = ['All', ...Object.keys(list)]
        if (appName === "esimcrew") {
          dropdownOptions = dropdownOptions?.filter(s => !disableSections?.includes(s))
        }
        dropdownOptions = dropdownOptions.map(id => ({id: id, name: pageConfig[id] ?? id})).sort()
        setFilteredSectionsList(dropdownOptions)
        setOriginalTranslatedList(cloneDeep(list)); 
      } else {
        const error = response.error;
        toast.current.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg || error.summary,
        });
      }
    });
  }, [appName, disableSections, dispatch, headers, selectedLanguage.code]);

  const handleInputChange = (page, key, value) => {
    setPendingUpdates((prevUpdates) => {
      const newUpdates = { ...prevUpdates };
      if (!newUpdates[page]) {
        newUpdates[page] = {};
      }
      newUpdates[page][key] = value;
      return newUpdates;
    });

    setTranslatedList((prevList) => {
      const updatedList = { ...prevList };
      if (!updatedList[page]) {
        updatedList[page] = {};
      }
      updatedList[page][key].selectedLanguage = value;
      return updatedList;
    });
  };

  const updateText = () => {
    setLoading(true)
    if (Object.keys(pendingUpdates).length > 0) {
      uploadTranslations(selectedLanguage.code, pendingUpdates, headers, dispatch, (response) => {
        setLoading(false)
        if (response.result === "SUCCESS") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Translations updated successfully",
          });
          setPendingUpdates({});
          setOriginalTranslatedList(cloneDeep(translatedList))
        } else {
          const error = response.error;
          toast.current.show({
            severity: error.severity,
            summary: "Error",
            detail: error.errorMsg || error.summary,
          });
        }
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "No Changes",
        detail: "No translations were changed.",
      });
    }
    setEditText(false);
  };

  const removeTextErrors = useCallback(() => {
    setTranslatedList(cloneDeep(originalTranslatedList));
    setPendingUpdates({});
    setEditText(false);
  }, [originalTranslatedList]);

  useEffect(() => {
    getTranslations();
  }, [getTranslations]);

  useEffect(() => {
    const isExistFn = text => text.toLowerCase()?.includes(searchText?.toLowerCase())
    const filteredTranslations = !!translatedList[filteredSection] ? {[filteredSection]: translatedList[filteredSection]} : translatedList;
    const selectedObject = Object.entries(filteredTranslations).flatMap(
      ([page, entry]) => Object.entries(entry).map(([key, item]) => ({
        page: page,
        key: key,
        english: item.english,
        selectedLanguage: item.selectedLanguage,
        errorMsg: item.errorMsg || "",
      }))
      .filter(s => !s.key.includes('.'))
      .filter(s => !isBase64Image(s.english))
      .filter(s => isExistFn(s.key) || isExistFn(s.english) || isExistFn(s.selectedLanguage))
    );
    setLanguageList(selectedObject);
  }, [translatedList, selectedLanguage, filteredSection, searchText]);

  return (
    <div className="language-translate">
      <div className="grid mb-2">
        <div className="col-12">
          <div className="flex align-items-center justify-content-between">
            <h2>English</h2>
            <div className="dropdown flex align-items-center gap-2">
              <div className="relative">
                <PR.InputText value={searchText} onChange={e => setSearchText(e.target.value)} className="language-search-field" placeholder="Search.." />
                <span className="pi pi-search"></span>
              </div>
              <PR.Dropdown 
              options={filteredSectionsList}
              value={filteredSection} 
              optionValue="id"
              optionLabel="name"
              onChange={e => setFilteredSection(e.value)}
              placeholder="Filter Screens"
              />
              <PR.Dropdown
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.value)}
                options={languages}
                optionLabel="name"
                placeholder="Select a language"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="custom-bg">
      {loading ? (
          <div className="col-12 text-center">
          <PR.ProgressSpinner />
          </div>
        ) : (
        languageList.map((lang,index) => (
          <div className="flex align-items-center mb-2" key={`${lang.page}-${lang.key}-${index}`}>
            <div className="col-6 pb-0">
              <div className="box">
                <div className="flex align-items-center english-text">
                  <div className="col-2 p-0">
                    <span>{lang.key}</span>
                  </div>
                  <div className="col-10 p-0">
                    <p>{lang.english}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 pb-0">
              <div className="box">
                <div className="flex align-items-center">
                  <div className="col">
                    <>
                      {!editText && <span>{lang.selectedLanguage}</span>}

                      {editText && (
                        <PR.InputText
                          value={lang.selectedLanguage}
                          onChange={(e) => handleInputChange(lang.page, lang.key, e.target.value)}
                          id="title"
                          name="title"
                        />
                      )}
                      <div className="priceError"> {lang.errorMsg}</div>
                    </>
                  </div>
                  <div className="icons">
                    <>
                      {!editText && (
                        <PR.Button
                          icon="pi pi-pencil"
                          className="action-btn edit"
                          onClick={() => setEditText(true)}
                        />
                      )}

                      {editText && (
                        <>
                          <PR.Button
                            icon="pi pi-check"
                            className="action-buttons"
                            onClick={updateText}
                          />

                          <PR.Button
                            icon="pi pi-times"
                            className="action-buttons close"
                            onClick={() => removeTextErrors()}
                          />
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
        )}
      </div>
      <PR.Toast ref={toast} position="top-right" />
    </div>
  );
};
export default LanguageTranslate;
