import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { getAffiliateUsersList } from "../../services/api";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { useSelector, useDispatch } from 'react-redux';
import { globalConfig } from "../../GlobalConfig";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';

const Affiliates = () => {
    const dispatch = useDispatch();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState("name");
    const [sortKey, setSortKey] = useState("-name");

    const [users, setUsers] = useState([]);
    const toast = useRef();
    const [loading, setLoading] = useState(true);

    const [pageCount] = useState(globalConfig.pageCount);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount + 1);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);
    const [affiliateName, setAffiliateName] = useState('');
    const [affiliateEmail, setAffiliateEmail] = useState('');
    const [affiliatePhone, setAffiliatePhone] = useState('');
    const [affiliateStatus, setAffiliateStatus] = useState([]);
    const [affiliatesData, setAffiliatesData] = useState(true);
    const [isCopied, setIsCopied] = useState(false);
    const [copiedValue, setCopiedValue] = useState('');

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
    };

    const getAllAffiliateUsers = useCallback(async () => {
        if (affiliatesData) {
            setLoading(true);
            const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
            const obj = {
                offset: offset,
                limit: limit,
                sortField: sortField,
                sortOrder: sortOrderVal,
                name: affiliateName,
                email: affiliateEmail,
                phone: affiliatePhone,
                status: affiliateStatus.join(','),
            };
            getAffiliateUsersList(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    const list = response.data;
                    const result = list !== null ? list.slice(0, pageCount) : [];
                    if (result.length > 0) {
                        setLast(list.length <= pageCount);
                        setUsers(result);
                        setPaging(true);
                    } else {
                        setUsers([]);
                        setLast(true);
                        setPaging(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'No records found' });
                    }
                } else {
                    setUsers([]);
                    setLast(true);
                    setPaging(false);
                    const error = response.error;
                    toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
                }
                setLoading(false);
            })
        }
    }, [sortOrder, offset, limit, sortField, affiliateName, affiliateEmail, affiliatePhone, headers, dispatch, pageCount, affiliatesData, affiliateStatus])


    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers])

    const paginate = (currentPage) => {
        setPaging(false);
        setPage(currentPage);
        const offsetVal = (currentPage - 1) * pageCount;
        const limitVal = pageCount + 1;
        setOffset(offsetVal);
        setLimit(limitVal);
    };

    const modifyAffiliateStatus = (rowData) => {
        const status = rowData.status === 'ACTIVE'
            ? 'completed'
            : rowData.status === 'SUSPENDED'
                ? 'pending'
                : 'failed'
        return (
            <p>
                <span
                    className={`status ${status}`}
                >{rowData.status}</span>
            </p>
        );
    };

    const handleCopyClipboard = (value) => {
        setCopiedValue(value);
        navigator.clipboard.writeText(value)
          .then(() => {
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 1500); // Reset the copied state after 1.5 seconds
          })
          .catch((error) => {
            console.error('Copy failed: ', error);
          });
      };

    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 border-0 custom-bg">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul">
                            <li className="affiliateId"> {rowData.afId} {isCopied && copiedValue === rowData.afId ? <span className="copiedText"><i className="pi pi-check"></i>Copied</span> : <i className="pi pi-copy" title="Copy Affiliate ID" onClick={() => handleCopyClipboard(rowData.afId)}></i>}</li>
                            <li className="affiliateUserName">{rowData.name}</li>
                            <li className="affiliateUserEmail">{rowData.email}</li>
                            <li className="affiliateUserPhone">{rowData?.phone ? rowData?.phone?.callingCode + " "+rowData?.phone?.localPhoneNumber: '-'} </li>
                            <li className="affiliateUserStatus">{modifyAffiliateStatus(rowData)}</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };


    const affiliatesDataHeader = (field, label, selector) => {
        return (
            (sortField !== field) ?
                <>
                    <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                </> :
                <>
                    {(sortKey === field) ?
                        <>
                            <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                        </> :
                        <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                    }
                </>
        );
    }

    const [initialValues] = React.useState({
        searchAffiliateName: affiliateName,
        searchAffiliateEmail: affiliateEmail,
        searchAffiliatePhone: affiliatePhone,
        searchAffiliateStatus: affiliateStatus
    })

    const validationSchema = Yup.object().shape({
        searchAffiliateName: Yup.string().trim(),
        searchAffiliateEmail: Yup.string().trim().email('Invalid email'),
        searchAffiliatePhone: Yup.string().trim(),
    })

    const resetUserForm = () => {
        formik.resetForm();
        setAffiliateName('');
        setAffiliateEmail('');
        setAffiliatePhone('');
        setAffiliateStatus([]);
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
    }

    const handleAffiliateSelectedStatus = (val) => {
        setAffiliateStatus(val);
        setAffiliatesData(false);
    }


    const handleSubmit = (formData) => {
        setAffiliatesData(true);
        setAffiliateName(encodeURIComponent(trimFormData(formData.searchAffiliateName)));
        setAffiliateEmail(encodeURIComponent(trimFormData(formData.searchAffiliateEmail)));
        setAffiliatePhone(encodeURIComponent(trimFormData(formData.searchAffiliatePhone)));
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
    }

    const trimFormData = (val) => {
        return val.trim();
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    const affiliateStatusOptions = [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
        { label: "Suspended", value: "suspended" }
    ];

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-affiliateslist-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <h2>Affiliates</h2>
                                    <div className="filter-right mt-5">
                                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                            <div className='flex align-items-center'>
                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Affiliate Name" name="searchAffiliateName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchAffiliateName} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliateName && formik.touched.searchAffiliateName
                                                        ? <div className='error-message'>{formik.errors.searchAffiliateName}</div>
                                                        : ''
                                                    }
                                                </div>


                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Email ID" name="searchAffiliateEmail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchAffiliateEmail} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliateEmail && formik.touched.searchAffiliateEmail
                                                        ? <div className='error-message'>{formik.errors.searchAffiliateEmail}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Phone Number" name="searchAffiliatePhone"
                                                            onChange={(e) => utils.handlePhoneChange(e, formik, 'searchAffiliatePhone')}
                                                            onBlur={formik.handleBlur} value={formik.values.searchAffiliatePhone} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliatePhone && formik.touched.searchAffiliatePhone
                                                        ? <div className='error-message'>{formik.errors.searchAffiliatePhone}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.MultiSelect filter resetFilterOnHide value={affiliateStatus} options={affiliateStatusOptions} onChange={(e) => handleAffiliateSelectedStatus(e.value)} name="searchOrderStatus" optionLabel="label" placeholder="Status" maxSelectedLabels={1} />
                                                    </span>
                                                    {formik.errors.searchOrderStatus && formik.touched.searchOrderStatus
                                                        ? <div className='error-message'>{formik.errors.searchOrderStatus}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Button label="Search" type='submit' className="searchBtn" />
                                                        <PR.Button label="Reset" type='reset' className="resetBtn" onClick={resetUserForm} />
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table card">
                            <div className="card">
                                <div className="custom-table">
                                    <div className="custom-table-header">
                                        <div className="table-grid">
                                            <ul className="col-ul sorting-li">
                                                <li className="affiliateId">Affiliate ID</li>
                                                {affiliatesDataHeader('name', 'Affiliate Name', 'affiliateUserName')}
                                                {affiliatesDataHeader('email', 'Email ID', 'affiliateUserEmail')}
                                                {affiliatesDataHeader('phone', 'Phone Number', 'affiliateUserPhone')}
                                                {affiliatesDataHeader('status', 'Status', 'affiliateUserStatus')}
                                            </ul>
                                        </div>
                                    </div>
                                    <PR.DataView
                                        loading={loading}
                                        value={users}
                                        itemTemplate={itemTemplate}
                                    />
                                </div>
                            </div>

                        </div>
                        {users.length > 0 ? (
                            <div className="pagination">
                                <button
                                    type="button"
                                    onClick={() => paginate(page - 1)}
                                    className={paging ? page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Previous}
                                </button>
                                <span className="active"> {page} </span>
                                <button
                                    type="button"
                                    onClick={() => paginate(page + 1)}
                                    className={paging ? last ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Next}
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <PR.Toast ref={toast} position='top-right' />
                    </section>
                    <AdminFooter />
                </div>
            </div>
        </>
    );
};

export default Affiliates;

