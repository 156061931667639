import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { globalConfig } from '../../GlobalConfig';
import { useSelector, useDispatch } from 'react-redux';
import { getCountriesList, getCatalogsList, updatePrices, importPriceCsv, getPriceFilters } from "../../services/api.jsx";
import "../price-adjustment/PriceAdjustment.scss";
import * as utils from '../../utils';

const PriceAdjustment = () => {
    const dispatch = useDispatch();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const toast = useRef();

    const [initialCatalogs, setInitialCatalogs] = useState([]);
    const [catalogs, setCatalogs] = useState([]);

    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState(0);

    const [uniqueRegions, setUniqueRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);

    const [allCountries, setAllCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('GB');

    const [catalogData, setCatalogData] = useState({});
    const [loading, setLoading] = useState(true);

    const [filtersVisible, setFiltersVisible] = useState(false);
    const [checkedStates, setCheckedStates] = useState([]);
    const [editedPriceRows, setEditedPriceRows] = useState({});
    const [editedOfferedRows, setEditedOfferedRows] = useState({});

    const [providerList, setProviderList] = useState([]);
    const [durationList, setDurationList] = useState([]);
    const [dataAmountList, setDataAmountList] = useState([]);

    const [selectedPlans, setSelectedPlans] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [selectedDurations, setSelectedDurations] = useState([]);
    const [selectedDataAmounts, setSelectedDataAmounts] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    const [filterSelectedRegion, setFilterSelectedRegion] = useState(null);

    const [filterCountries, setFilterCountries] = useState([]);
    const [filterCountry, setFilterCountry] = useState('GB');

    const [filters, setFilters] = useState({});

    const setDataAmount = (rowData) => {
        return (rowData.dataAmountForDisplay) ? rowData.dataAmountForDisplay : ((rowData.dataAmount / 1000) + (globalConfig.dataSize));
    };

    const setDataDuration = (rowData) => {
        return (rowData.duration && (rowData.duration) + " Days");
    };

    const setDataSpeed = (rowData) => {
        return (rowData.speed && (rowData.speed.join('/')));
    };
    const removePriceErrors = useCallback(() => {
        setCatalogs((prevCatalogs) =>
            prevCatalogs.map((catalog) => {
                const { errorMsg, ...rest } = catalog;
                return rest;
            })
        );
    }, [])

    const getCatalogs = useCallback(async () => {
        setLoading(true);
        const defaultFilters = {
            sortingOrder: 'asc',
            sortingField: 'providerprice',
            country,
        };
        const queryParams = utils.serializeParams({ ...filters, ...defaultFilters });
        
        const handleResponse = (response) => {
            setLoading(false);
            if (response.result === "SUCCESS") {
                const catalogList = response.data || [];
                if (Array.isArray(catalogList) && catalogList.length > 0) {
                    setInitialCatalogs(catalogList);
                    setCatalogs(catalogList);
                    const assignedOfferedList = catalogList?.map(item => ({
                        [item.name]: item.offered || false
                      }))
                    setCheckedStates(assignedOfferedList);
                } else {
                    setCatalogs([]);
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No plans for the selected country' });
                }
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
            }
        };

        setCatalogData({});
        getCatalogsList(queryParams, headers, dispatch, handleResponse);
    }, [country, dispatch, filters, headers]);

    useEffect(() => {
        const handleCountriesResponse = (response) => {
            if (response.result === "SUCCESS") {
                const countriesData = response.data || [];
                if (countriesData.length > 0) {
                    const countriesList = countriesData.map(({ name, region, iso }) => ({
                        name: name.split('(')[0].trim(),
                        region,
                        iso
                    }));
                    setAllCountries(countriesList);
                    setCountries(countriesList);
                    setFilterCountries(countriesList);
                    const uniqueRegionList = [...new Set(countriesList.map(item => item.region))].map(region => ({ label: region, value: region }));
                    const unemptyRegionList = uniqueRegionList.filter(region => region.label && region.value); // Removed empty object from the region list
                    setUniqueRegions(unemptyRegionList);
                }
            }
        };
        getCountriesList(headers, dispatch, handleCountriesResponse);
    }, [dispatch, headers]);

    useEffect(() => {
        getCatalogs();
    }, [getCatalogs]);

    useEffect(() => {
        setIsChanged(
            filterSelectedRegion ||
            filterCountry ||
            selectedPlans !== false ||
            selectedProviders.length > 0 ||
            selectedDurations.length > 0 ||
            selectedDataAmounts.length > 0
        );
    }, [selectedPlans, selectedProviders, selectedDurations, selectedDataAmounts, filterSelectedRegion, filterCountry]);

 const handleRegionSelection = (region) => {
        setSelectedRegion(region);
        setFilterSelectedRegion(region);
        const countriesByRegion = region ? allCountries?.filter(c => c.region === region) : allCountries;
        setCountries(countriesByRegion);
        setFilterCountries(countriesByRegion);
        const countryDisplay = countriesByRegion?.find(c => c.iso === country) ? country : null;
        setCountry(region ? countryDisplay : 'GB');
        setFilters(prev => ({ ...prev, region: region }));
    };

    const handleCountrySelection = (e) => {
        const selectedCountry = e.value;
        setCountry(selectedCountry);
        setFilterCountry(selectedCountry);
        setFilters(prev => ({ ...prev, region: selectedRegion, country: selectedCountry }));
    };

    const regionFilterHandler = (region) => {
        setFilterSelectedRegion(region);
        const countriesByRegion = region ? allCountries?.filter(c => c.region === region) : allCountries;
        const countryDisplay = countriesByRegion?.find(c => c.iso === filterCountry) ? filterCountry : null;
        setFilterCountry(region? countryDisplay : 'GB');
        setFilterCountries(countriesByRegion);
    }

    const countryFilterHandler = (e) => {
        setFilterCountry(e.value);
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        setFiltersVisible(false);
        setCountries(filterCountries);
        const countryDisplay = filterCountries?.find(c => c.iso === filterCountry) ? filterCountry : null;
        setCountry(countryDisplay);
        setSelectedRegion(filterSelectedRegion);
        setFilters(prev => ({
            ...prev,
            region: filterSelectedRegion,
            country: filterCountry,
            offered: selectedPlans ? selectedPlans : undefined,
            providerName: selectedProviders.includes('All') ? undefined : selectedProviders,
            planDuration: selectedDurations,
            dataAmountForDisplay: selectedDataAmounts
        }));
    };

    const resetData = () => {
        setCatalogs([...initialCatalogs]);
        const assignedOfferedList = initialCatalogs?.map(item => ({
            [item.name]: item.offered || false
          }))
        setCheckedStates(assignedOfferedList);
        setEditedPriceRows({});
        setEditedOfferedRows({});
        removePriceErrors();
        setSelectedRegion(null);
        setCountries(allCountries);
        setCountry('GB');
        setFilterSelectedRegion(null);
        setFilters([]);
        handleFilterReset();
    };

    const handleFilterReset = () => {
        setFilterSelectedRegion(null);
        setFilterCountries(allCountries);
        setFilterCountry('GB');
        setSelectedPlans(false);
        setSelectedProviders([]);
        setSelectedDurations([]);
        setSelectedDataAmounts([]);
    };

    const updateCatalogPrices = useCallback(async (catalogList) => {
        updatePrices(catalogList, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                getCatalogs();
                setEditedPriceRows({}); // Clear edited rows
                setEditedOfferedRows({});
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Price updated successfully' })
            } else {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        })
    }, [dispatch, headers, getCatalogs])

    const handleCheckboxChange = (rowData, checked) => {
        const updatedCatalogs = catalogs.map((item, i) =>
            item.name === rowData.name ? { ...item, offered: checked } : item
        );
        setCatalogs(updatedCatalogs);
        let existingCatalogData = { ...catalogData };
        const findCatalog = initialCatalogs.find(item => item.name === rowData.name);
        if(findCatalog?.offered !== checked) {
            const reqStucturedObj = { 'name': rowData.name, 'price': rowData.ourPrice, 'providerPrice': rowData.providerPrice, 'offered': checked };
            existingCatalogData[rowData.name] = reqStucturedObj;
            setCatalogData(existingCatalogData);
        } else {
            if(existingCatalogData[rowData.name]){
                delete existingCatalogData[rowData.name];
            }
            setCatalogData(existingCatalogData);
        }
        // Update the editedRows state
        const initialIndex = initialCatalogs.findIndex(item => item.name === rowData.name);
        if (initialCatalogs[initialIndex].offered !== checked) {
            setEditedOfferedRows(prevEditedRows => ({
                ...prevEditedRows,
                [rowData.name]: true,
            }));
        } else {
            setEditedOfferedRows(prevEditedRows => {
                const { [rowData.name]: _, ...rest } = prevEditedRows;
                return rest;
            });
        }
        setCheckedStates(prevCheckedStates => {
            const newCheckedStates = prevCheckedStates.map((state, i) => {
                if (state.hasOwnProperty(rowData.name)) {
                    return { [rowData.name]: checked };
                }
                return state;
            });
            return newCheckedStates;
        });
    };

    const onPriceEdit = (rowData, e) => {
        const planName = rowData.name;
        const providerPrice = rowData.providerPrice;
        const offered = rowData.offered;
        const price = e.value;
        let planData = { ...catalogData };
        if (price && price !== 0) {
            if (providerPrice > price) {
                delete planData[planName];
                const priceErrordata = 'Price should be greater than Provider Price';
                rowData.errorMsg = priceErrordata;
            } else {
                const planPrice = utils.setNumberPrecision(price);
                const providerPriceVal = utils.setNumberPrecision(providerPrice);
                const catalogDet = { 'name': planName, 'price': parseFloat(planPrice), 'providerPrice': parseFloat(providerPriceVal), 'offered': offered };
                planData[planName] = catalogDet;
                delete rowData.errorMsg;
            }
        } else {
            delete planData[planName];
            const priceErrordata = 'Price is invalid';
            rowData.errorMsg = priceErrordata;
        }
        setCatalogData(planData);
        setCatalogs(prevCatalogs => {
            const updatedCatalogs = prevCatalogs.map(item =>
                item.name === planName ? { ...item, ourPrice: price, errorMsg: rowData.errorMsg } : item
            );
            return updatedCatalogs;
        });
        // Update the editedRows state
        const rowIndex = initialCatalogs.findIndex(item => item.name === planName);
        if (initialCatalogs[rowIndex].ourPrice !== price) {
            setEditedPriceRows(prevEditedRows => ({
                ...prevEditedRows,
                [rowData.name]: true,
            }));
        } else {
            setEditedPriceRows(prevEditedRows => {
                const { [rowData.name]: _, ...rest } = prevEditedRows;
                return rest;
            });
        }
    };

    const priceBodyTemplate = (rowData, props) => {
        return (
            <>
                <div className="list-price">
                    <span className="currencyVal">{globalConfig.defaultCurrency}</span>
                    <PR.InputNumber
                        value={rowData.ourPrice}
                        onKeyDown={utils.blockInvalidChar}
                        onChange={(e) => onPriceEdit(rowData, e)}
                        minFractionDigits={2}
                    />
                </div>
                {rowData.errorMsg ? <div className="priceError"> {rowData.errorMsg} </div> : ''}
            </>
        )
    };

    const providerPriceBodyTemplate = (rowData) => {
        return (
            <span>{utils.providerPrice(rowData.providerPrice)}</span>
        )
    };

    const updatePrice = () => {
        if (Object.keys(catalogData).length !== 0) {
            const catalogList = Object.keys(catalogData).map(name => ({ name, price: catalogData[name].price, providerPrice: catalogData[name].providerPrice, offered: catalogData[name].offered }));
            updateCatalogPrices(catalogList);
        }
    }

    const getItemCheckedState = (checkedStates, itemName) => {
        const state = checkedStates.find(state => state.hasOwnProperty(itemName));
        return state ? state[itemName] : false;
      };

    const offeredCheckboxTemplate = (rowData) => {
        const itemName = rowData.name;
        const itemChecked = getItemCheckedState(checkedStates, itemName);
        return (
            <div className="card flex justify-content-center">
                <PR.Checkbox
                    onChange={e => handleCheckboxChange(rowData, e.checked)}
                    checked={itemChecked}
                    className="offered-column"
                ></PR.Checkbox>
            </div>
        );
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file.type === 'text/csv') {
            const formData = new FormData();
            formData.append('file', file);

            const selectedCsvFile = (response) => {
                setLoading(false);
                if (response.result === "SUCCESS") {
                    if (response.data === 'success') {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Prices updated successfully' });
                        getCatalogs();
                    } else {
                        toast.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.errorMsg });
                    }
                } else {
                    const error = response.error;
                    toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
                }
            }
            setLoading(true);
            importPriceCsv(formData, headers, dispatch, selectedCsvFile)
        } else {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Uploaded file should be in .csv format' })
        }
        e.target.value = '';
    }

    const exportCSV = (e) => {
        setLoading(true);
        e.preventDefault();
        const defaultFilters = {
            sortingOrder: 'asc',
            sortingField: 'providerprice',
        };
        const queryParams = utils.serializeParams({ ...defaultFilters });
        getCatalogsList(queryParams, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                setLoading(false);
                const data = response.data ? response.data : [];
                 // Keys to extract
                const requiredKeys = ['country', 'iso', 'providerName', 'name', 'description', 'providerPrice', 'ourPrice', 'offered'];
                const exportArray = utils.extractRequiredKeys(data, requiredKeys)
                if (exportArray && exportArray.length > 0) {
                    const capitalizeFirstLetter = (str) => {
                        if (str === 'providerPrice') {
                            return 'Buy Price';
                        } else if (str === 'ourPrice') {
                            return 'List Price';
                        } else if (str === 'providerName') {
                            return 'Provider';
                        } else {
                            return str.charAt(0).toUpperCase() + str.slice(1);
                        }
                    };
                    const csvContent = "data:text/csv;charset=utf-8," +
                        Object.keys(exportArray[0]).map(capitalizeFirstLetter).join(",") + "\n" +
                        exportArray.map(entry => {
                            return Object.values(entry).map(value => {
                                if (typeof value === 'string' && (value.includes(' ') || value.includes(','))) {
                                    return `"${value}"`;
                                }
                                return value;
                            }).join(",");
                        }).join("\n");
                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "catalog_data.csv");
                    document.body.appendChild(link);
                    link.click();
                }
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Exported successfully' });
            } else {
                setLoading(false);
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error?.errorMsg) ? error.errorMsg : error?.summary });
            }
        });
    };

    const rowClassName = (rowData) => {
        let classNames = '';
        if (!rowData.offered) {
            classNames += ' row-disabled';
        }
    
        if (editedPriceRows[rowData.name] || editedOfferedRows[rowData.name]) {
            classNames += ' row-edited';  // Add a class when the row is edited
        }

        return classNames;
    };
    
    useEffect(() => {
        getPriceFilters(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                setDataAmountList(response.data?.dataAmountForDisplay?.length > 0 ? [...new Set(response.data?.dataAmountForDisplay)] : []);
                setDurationList(response.data?.duration?.length > 0 ? [...new Set(response.data?.duration)] : []);
                // Include "All" option for providers
                const providers = response.data?.providers?.length > 0 ? ['All', ...new Set(response.data?.providers)] : [];
                setProviderList(providers);
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error?.errorMsg) ? error?.errorMsg : error?.summary })
            }
        })
    }, [dispatch, headers]);

    const handleProviderChange = (e, provider) => {
        if (provider === 'All') {
            if (e.checked) {
                setSelectedProviders(providerList);
            } else {
                setSelectedProviders([]);
            }
        } else {
            const newSelectedProviders = e.checked
                ? [...selectedProviders, provider]
                : selectedProviders.filter((p) => p !== provider);

            if (newSelectedProviders.length === providerList.length - 1 && !newSelectedProviders.includes('All')) {
                setSelectedProviders([...newSelectedProviders, 'All']);
            } else {
                setSelectedProviders(newSelectedProviders.filter((p) => p !== 'All'));
            }
        }
    };

    const handleDurationChange = (e, duration) => {
        const newSelectedDurations = e.checked
            ? [...selectedDurations, duration]
            : selectedDurations.filter((d) => d !== duration);
        setSelectedDurations(newSelectedDurations);
    };

    const handleDataAmountChange = (e, data) => {
        const newSelectedDataAmounts = e.checked
            ? [...selectedDataAmounts, data]
            : selectedDataAmounts.filter((d) => d !== data);
        setSelectedDataAmounts(newSelectedDataAmounts);
    };

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-users-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="flex align-items-center justify-content-between">
                                        <h1>Price Adjustment</h1>
                                        <div className="right-section flex align-items-center gap-3">
                                            <div className="file-upload-section">
                                                <label className="placeholder-text"><i className="pi pi-upload"></i> Upload data</label>
                                                <PR.InputText type="file" name="file" className="file-upload-input csv-file" disabled={loading} accept=".csv" onChange={e => handleFileUpload(e)} />
                                            </div>
                                            <PR.Button label="Export CSV" className="export-button" icon="pi pi-file-export" iconPos="left" onClick={exportCSV} disabled={loading}/>
                                        </div>
                                    </div>
                                    <div className="flex align-items-center justify-content-between mt-5">
                                            <div className="flex gap-3 align-items-center">
                                                <PR.Dropdown value={selectedRegion} onChange={(e) => handleRegionSelection(e.value)} options={uniqueRegions} optionLabel="label" placeholder="All Regions" filter className="w-full md:w-14rem" disabled={loading} 
                                                showClear={selectedRegion ? true : false}/>
                                                <PR.Dropdown
                                                    filter
                                                    disabled={loading}
                                                    value={country}
                                                    options={countries}
                                                    onChange={handleCountrySelection}
                                                    resetFilterOnHide
                                                    optionLabel="name"
                                                    optionValue="iso"
                                                    placeholder="All Countries"
                                                    // showClear={country ? true : false}
                                                />
                                            </div>
                                        <PR.Button label="Filter" className="exportBtn" icon="pi pi-filter" onClick={() => setFiltersVisible(true)} disabled={loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="users-data-table price-adjustment plan-selection card">
                            <PR.DataTable
                                loading={loading}
                                editMode="row"
                                value={catalogs}
                                responsiveLayout="scroll"
                                rowClassName={rowClassName}
                                let-i="rowIndex"
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={(e) => {
                                    setSortField(e.sortField);
                                    setSortOrder(e.sortOrder);
                                }}
                                >
                                <PR.Column field="providerName" header="Provider" sortable></PR.Column>
                                <PR.Column field="description" header="Plan" sortable />
                                <PR.Column field="dataAmount" header="Data" body={setDataAmount} sortable />
                                <PR.Column field="duration" header="Duration" body={setDataDuration} sortable />
                                <PR.Column field="speed" header="Speed" body={setDataSpeed} />
                                <PR.Column field="providerPrice" header="Buy Price" body={providerPriceBodyTemplate} sortable />
                                <PR.Column field="ourPrice" header="List price" body={priceBodyTemplate} sortable />
                                <PR.Column filed="offered" header="Selection" className="last-column" body={offeredCheckboxTemplate} />
                            </PR.DataTable>
                        </div>
                        <div className="buttons-sections flex align-items-center justify-content-end gap-3">
                            <PR.Button label="Reset" className="confirm-button reset-btn" onClick={resetData} disabled={((!Object.keys(editedPriceRows).length && !Object.keys(editedOfferedRows).length) && country === null && selectedRegion === null) && !isChanged} />
                            <PR.Button label="Confirm selection" className="confirm-button" onClick={() => updatePrice()} disabled={(!Object.keys(editedPriceRows).length && !Object.keys(editedOfferedRows).length) || !Object.keys(catalogData).length} />
                        </div>
                    </section>

                    <PR.Dialog header="Filter" visible={filtersVisible} onHide={() => { if (!filtersVisible) return; setFiltersVisible(false); }} draggable={false} blockScroll={true} className="filter-dialog">
                        <form autoComplete="off" className="mt-0" onSubmit={handleFilterSubmit}>
                            <div className="flex gap-3 align-items-center">
                                <PR.Dropdown value={filterSelectedRegion} onChange={(e) => regionFilterHandler(e.value)} options={uniqueRegions} optionLabel="label" placeholder="All Regions" filter className="w-full md:w-14rem" disabled={loading} resetFilterOnHide showClear="true"/>
                                <PR.Dropdown
                                    filter
                                    disabled={loading}
                                    value={filterCountry}
                                    options={filterCountries}
                                    onChange={countryFilterHandler}
                                    resetFilterOnHide
                                    optionLabel="name"
                                    optionValue="iso"
                                    placeholder="All Countries"
                                   //  showClear="true"
                                />
                                <div className="flex mt-2">
                                    <PR.Checkbox name="selectedPlans" onChange={(e) => setSelectedPlans(e.checked)} checked={selectedPlans}></PR.Checkbox>
                                    <label htmlFor="selectedPlans" className="ml-2">Show only selected plans</label>
                                </div>
                            </div>
                            {providerList.length > 0 && <div className="card mt-4">
                                <p>By list of providers</p>
                                <div className="flex gap-5">
                                    {
                                        providerList.length > 0 && providerList.map((provider, index) =>
                                            <div className="flex" key={index}>
                                                <PR.Checkbox inputId={`provider${index}`} checked={selectedProviders.includes(provider)} onChange={(e) => handleProviderChange(e, provider)}></PR.Checkbox>
                                                <label htmlFor="provider" className="ml-2">{provider}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>}
                            {durationList.length > 0 && <div className="card mt-4">
                                <p>By plan duration</p>
                                <div className="flex gap-5 flex-wrap">
                                    {
                                        durationList.length > 0 && durationList.map((duration, index) =>
                                            <div className="flex" key={index}>
                                                <PR.Checkbox inputId={`duration${index}`} checked={selectedDurations.includes(duration)} onChange={(e) => handleDurationChange(e, duration)}></PR.Checkbox>
                                                <label htmlFor="duration" className="ml-2">{duration} {duration > 1 ? 'days' : 'day'}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>}
                            {dataAmountList.length > 0 && <div className="card mt-4">
                                <p>By amount of data</p>
                                <div className="flex gap-5 amount-data-options flex-wrap">
                                    {
                                        dataAmountList.length > 0 && dataAmountList.map((data, index) =>
                                            <div className="flex" key={index}>
                                                <PR.Checkbox inputId={`data${index}`} checked={selectedDataAmounts.includes(data)} onChange={(e) => handleDataAmountChange(e, data)}></PR.Checkbox>
                                                <label htmlFor="amount" className="ml-2">{data}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>}
                            <div className="buttons-sections flex align-items-center justify-content-end gap-3">
                                <PR.Button label="Reset" type="button" className="confirm-button reset-btn min-width" onClick={handleFilterReset} disabled={!isChanged}/>
                                <PR.Button label="Apply" type="submit" className="confirm-button min-width"/>
                            </div>
                        </form>
                    </PR.Dialog>
                    <PR.Toast ref={toast} position='top-right' />
                    <AdminFooter />
                </div>
            </div>
        </>
    );
};

export default PriceAdjustment;