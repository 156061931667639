import React, { useState, useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { uploadAssetsApi } from "../../../services/api"
import "./General.scss";
import "../Cms.scss"
import { handlePhoneChange, validateTextField } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import ColorPickerComponent from '../components/ColorPickerComponent';
import { trimFormData } from '../../../utils';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import ImagePreview from '../generic/imagePreview';
import FontPicker from "font-picker-react"
import { uploadWidthHeightImage } from '../../../utils/reuse';


const General = ({assets, disableSave, themeColors}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null);
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [selectedLogoDark, setSelectedLogoDark] = useState(null);
    const [selectedLogoLight, setSelectedLogoLight] = useState(null);

    const { GEN0001, GEN0002, GEN0003, GEN0004, GEN0007, GEN0008, GEN0009, GEN0010 } = assets ? assets : {};

    const validationSchema = Yup.object().shape({
        GEN0001: validateTextField(),
        GEN0008: Yup.string().email("Invalid email address").required("Email is required"),
        GEN0009: validateTextField(),
        GEN0010: validateTextField(),
    })

    const handleSubmit = async (data) => {
        const formData = new FormData();
        const colorsFormData = new FormData();
        const trimmedFormData = trimFormData(data);
        if (trimmedFormData.colors) {
            Object.keys(trimmedFormData.colors).forEach(key => {
                colorsFormData.append(key, JSON.stringify(trimmedFormData.colors[key]));
            });
            delete trimmedFormData.colors;
        }
        if (trimmedFormData.GEN0006) {
            Object.keys(trimmedFormData.GEN0006).forEach(key => {
                formData.append(`GEN0006.${key}`, JSON.stringify(trimmedFormData.GEN0006[key]));
            });
            delete trimmedFormData.GEN0006;
        }
        formData.append('screen', 'general');
        colorsFormData.append('screen', 'themeColors');
        for (const field in trimmedFormData) {
            formData.append(field, trimmedFormData[field]);
        }
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if (response.data === 'success') {
                    getAllAssets(toast, dispatch, headers);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
                }
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(colorsFormData, headers, dispatch, ()=>{});
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
    }

    const getColor = (value) => {
        return value ? value.replace(/\\/g, '').replace(/"/g, '') : '';
    }

    const formik = useFormik({
        initialValues: {
            GEN0001, 
            GEN0002,
            GEN0003,
            GEN0004,
            colors: {
                PRIMARY: getColor(themeColors?.PRIMARY),
                SECONDARY: getColor(themeColors?.SECONDARY),
                TERITARY: getColor(themeColors?.TERITARY),
                QUATERNARY: getColor(themeColors?.QUATERNARY),
                QUINARY: getColor(themeColors?.QUINARY),
                BTNPRIMARY: getColor(themeColors?.BTNPRIMARY),
                BTNPRIMARYOVER: getColor(themeColors?.BTNPRIMARYOVER),
                BTNSECONDARY: getColor(themeColors?.BTNSECONDARY),
                BTNSECONDARYOVER: getColor(themeColors?.BTNSECONDARYOVER),
                MAINMENU: getColor(themeColors?.MAINMENU),
                MAINMENUOVER: getColor(themeColors?.MAINMENUOVER),
                DROPDOWNBG: getColor(themeColors?.DROPDOWNBG),
                DROPDOWNSELECTED: getColor(themeColors?.DROPDOWNSELECTED),
                DROPDOWNSELECTEDOVER: getColor(themeColors?.DROPDOWNSELECTEDOVER),
                INPUTFIELD: getColor(themeColors?.INPUTFIELD),
                INPUTFIELDBORDER: getColor(themeColors?.INPUTFIELDBORDER)
            },
            GEN0006: {
                fontBase: getColor(assets["GEN0006.fontBase"]),
                fontSecondary: getColor(assets["GEN0006.fontSecondary"]),
            },
            GEN0007,
            GEN0008,
            GEN0009,
            GEN0010
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        enableReinitialize: true, 
    })

    const handleEmailChange = (event, formik, fieldName) => {
        const { value } = event.target;
        formik.setFieldValue(fieldName, value);
        formik.setFieldTouched(fieldName, true);
        formik.validateField(fieldName);
    };
    
    return (
        <div className='general'>
            <div className="card">
                {/* <h2>General Settings</h2> */}
                <form autoComplete="off" onSubmit={formik.handleSubmit} >
                    <div className="imageFormat mb-4 ">
                        <strong>(Image Size Should not exceed 1MB)</strong>
                    </div>
                    {/* Brand Name */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-2'>
                            <label htmlFor="GEN0001" className="cmsLabels">Brand Name <span className='imageFormat'>(Accepts 4 to 50 characters)</span></label>
                        </div>
                        <div className="col-5">
                            <div className="card">
                                <PR.InputText id="GEN0001" name="GEN0001" aria-describedby="brandName" value={formik.values.GEN0001} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {formik.touched.GEN0001 && formik.errors.GEN0001 ? (
                                    <div className='error-msg'>{formik.errors.GEN0001}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-2'>
                            <label htmlFor="GEN0009" className="cmsLabels">Meta Name <span className='imageFormat'>(Accepts 4 to 50 characters)</span></label>
                        </div>
                        <div className="col-5">
                            <div className="card">
                                <PR.InputText id="GEN0009" name="GEN0009" aria-describedby="metaName" value={formik.values.GEN0009} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {formik.touched.GEN0009 && formik.errors.GEN0009 ? (
                                    <div className='error-msg'>{formik.errors.GEN0009}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-2'>
                            <label htmlFor="GEN0010" className="cmsLabels">Meta Description <span className='imageFormat'>(Accepts 4 to 50 characters)</span></label>
                        </div>
                        <div className="col-5">
                            <div className="card">
                                <PR.InputText id="GEN0010" name="GEN0010" aria-describedby="brandName" value={formik.values.GEN0010} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {formik.touched.GEN0010 && formik.errors.GEN0010 ? (
                                    <div className='error-msg'>{formik.errors.GEN0010}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    {/* Dark Logo */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className="col-2">
                            <label htmlFor="logoDarkVersion" className="cmsLabels"> Logo Dark Version <span className='imageFormat'>(png, jpeg, 143 X 61)</span> </label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    type="file"
                                    name="GEN0002"
                                    className="file-upload-input"
                                    accept="image/png, image/jpeg"
                                    onChange={(event) => {
                                        const file = event.currentTarget.files[0];
                                        setSelectedLogoDark(file);
                                        formik.handleChange(event);
                                        formik.setFieldValue("GEN0002", file);
                                        uploadWidthHeightImage(file, "GEN0002", toast, dispatch, headers, 'general')
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.GEN0002 && formik.errors.GEN0002 ? (
                                    <div className='error-msg'>{formik.errors.GEN0002}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='col-5'>
                                <ImagePreview formikError={formik.errors?.GEN0002} defaultImage={GEN0002} newImage={selectedLogoDark}/>
                        </div>
                    </div>

                    {/* Light Logo */}
                    <div className="grid grid-nogutter align-items-center mb-4">
                        <div className='col-2'>
                            <label htmlFor="logoLightVersion" className="cmsLabels">Logo Light Version <span className='imageFormat'>(png, jpeg, 143W X 61H)</span> </label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    type="file"
                                    name="GEN0003"
                                    className="file-upload-input"
                                    accept="image/png, image/jpeg"
                                    onChange={(event) => {
                                        const file = event.currentTarget.files[0];
                                        setSelectedLogoLight(file);
                                        formik.setFieldValue("GEN0003", file);
                                        uploadWidthHeightImage(file, "GEN0003", toast, dispatch, headers, 'general')
                                    }}
                                    onBlur={formik.handleBlur} />
                                {formik.touched.GEN0003 && formik.errors.GEN0003 ? (
                                    <div className='error-msg'>{formik.errors.GEN0003}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className='col-5'>
                            <ImagePreview formikError={formik.errors.GEN0003} defaultImage={GEN0003} newImage={selectedLogoLight}/>
                        </div>
                    </div>

                    {/* Favicon */}
                    {/* <div className="grid grid-nogutter align-items-center mb-4">
                        <div className="col-2">
                            <label htmlFor="favicon" className="cmsLabels">Favicon <span className='imageFormat'>(png, jpeg, 30W X 30H)</span> </label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    type="file"
                                    name="GEN0004"
                                    className="file-upload-input"
                                    accept="image/png"
                                    onChange={(event) => {
                                        const file = event.currentTarget.files[0];
                                        setSelectedFavicon(file);
                                        formik.setFieldValue("GEN0004", file);
                                    }}
                                    onBlur={formik.handleBlur} />
                                {formik.touched.GEN0004 && formik.errors.GEN0004 ? (
                                    <div className='error-msg'>{formik.errors.GEN0004}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='col-5'>
                            <ImagePreview formikError={formik.errors.GEN0004} defaultImage={GEN0004} newImage={selectedFavicon}/>
                        </div>
                    </div> */}

                    {/* Color Palette */}
                    <div className="grid grid-nogutter align-items-top mb-4">
                        <div className="col-2">
                            <label htmlFor="colorPalette" className="cmsLabels">Color Theme</label>
                        </div>
                        <div className="col-10">
<div className='colorGrid'>
                            
                            <div className="colorPickerPanel">
                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Primary Color"
                                        color={formik.values.colors?.PRIMARY}
                                        onChange={(newColor) => formik.setFieldValue("colors.PRIMARY", newColor)}
                                    />
                                    {formik.touched.colors?.PRIMARY && formik.errors.colors?.PRIMARY && (
                                        <div className='error-msg'>{formik.errors.colors?.PRIMARY}</div>
                                    )}
                                </div>

                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Secondary Color"
                                        color={formik.values.colors?.SECONDARY}
                                        onChange={(newColor) => formik.setFieldValue("colors.SECONDARY", newColor)}
                                    />
                                    {formik.touched.colors?.SECONDARY && formik.errors.colors?.SECONDARY && (
                                       <div className='error-msg'>{formik.errors.colors?.SECONDARY}</div>
                                    )}
                                </div>

                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Teritary Color"
                                        color={formik.values.colors?.TERITARY}
                                        onChange={(newColor) => formik.setFieldValue("colors.TERITARY", newColor)}
                                    />
                                    {formik.touched.colors?.TERITARY && formik.errors.colors?.TERITARY && (
                                       <div className='error-msg'>{formik.errors.colors?.TERITARY}</div>
                                    )}
                                </div>

                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Quaternary Color"
                                        color={formik.values.colors?.QUATERNARY}
                                        onChange={(newColor) => formik.setFieldValue("colors.QUATERNARY", newColor)}
                                    />
                                    {formik.touched.colors?.QUATERNARY && formik.errors.colors?.QUATERNARY && (
                                       <div className='error-msg'>{formik.errors.colors?.QUATERNARY}</div>
                                    )}
                                </div>
                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Quinary Color"
                                        color={formik.values.colors?.QUINARY}
                                        onChange={(newColor) => formik.setFieldValue("colors.QUINARY", newColor)}
                                    />
                                    {formik.touched.colors?.QUINARY && formik.errors.colors?.QUINARY && (
                                       <div className='error-msg'>{formik.errors.colors?.QUINARY}</div>
                                    )}
                                </div>
                            </div>  
                            <div className="colorPickerPanel">
                            <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Main Menu"
                                        color={formik.values.colors?.MAINMENU}
                                        onChange={(newColor) => formik.setFieldValue("colors.MAINMENU", newColor)}
                                    />
                                    {formik.touched.colors?.MAINMENU && formik.errors.colors?.MAINMENU && (
                                        <div className='error-msg'>{formik.errors.colors.MAINMENU}</div>
                                    )}
                                </div>
                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Main Menu Hover"
                                        color={formik.values.colors?.MAINMENUOVER}
                                        onChange={(newColor) => formik.setFieldValue("colors.MAINMENUOVER", newColor)}
                                    />
                                    {formik.touched.colors?.MAINMENUOVER && formik.errors.colors?.MAINMENUOVER && (
                                        <div className='error-msg'>{formik.errors.colors.MAINMENUOVER}</div>
                                    )}
                                </div>
                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Dropdown Background"
                                        color={formik.values.colors?.DROPDOWNBG}
                                        onChange={(newColor) => formik.setFieldValue("colors.DROPDOWNBG", newColor)}
                                    />
                                    {formik.touched.colors?.DROPDOWNBG && formik.errors.colors?.DROPDOWNBG && (
                                        <div className='error-msg'>{formik.errors.colors.DROPDOWNBG}</div>
                                    )}
                                </div>

                                <div className="colorPickers border-light">
                                    <ColorPickerComponent
                                        label="Dropdown Selected"
                                        color={formik.values.colors?.DROPDOWNSELECTED}
                                        onChange={(newColor) => formik.setFieldValue("colors.DROPDOWNSELECTED", newColor)}
                                    />
                                    {formik.touched.colors?.DROPDOWNSELECTED && formik.errors.colors?.DROPDOWNSELECTED && (
                                        <div className='error-msg'>{formik.errors.colors.DROPDOWNSELECTED}</div>
                                    )}
                                </div>

                                <div className="colorPickers border-light">
                                    <ColorPickerComponent
                                        label="Dropdown Selected Hover"
                                        color={formik.values.colors?.DROPDOWNSELECTEDOVER}
                                        onChange={(newColor) => formik.setFieldValue("colors.DROPDOWNSELECTEDOVER", newColor)}
                                    />
                                    {formik.touched.colors?.DROPDOWNSELECTEDOVER && formik.errors.colors?.DROPDOWNSELECTEDOVER && (
                                        <div className='error-msg'>{formik.errors.colors.DROPDOWNSELECTEDOVER}</div>
                                    )}
                                </div>
                            </div>
                            <div className="colorPickerPanel">
                            <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Button Primary"
                                        color={formik.values.colors?.BTNPRIMARY}
                                        onChange={(newColor) => formik.setFieldValue("colors.BTNPRIMARY", newColor)}
                                    />
                                    {formik.touched.colors?.BTNPRIMARY && formik.errors.colors?.BTNPRIMARY && (
                                        <div className='error-msg'>{formik.errors.colors.BTNPRIMARY}</div>
                                    )}
                                </div>

                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Button Primary Hover"
                                        color={formik.values.colors?.BTNPRIMARYOVER}
                                        onChange={(newColor) => formik.setFieldValue("colors.BTNPRIMARYOVER", newColor)}
                                    />
                                    {formik.touched.colors?.BTNPRIMARYOVER && formik.errors.colors?.BTNPRIMARYOVER && (
                                        <div className='error-msg'>{formik.errors.colors.BTNPRIMARYOVER}</div>
                                    )}
                                </div>

                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Button Secondary"
                                        color={formik.values.colors?.BTNSECONDARY}
                                        onChange={(newColor) => formik.setFieldValue("colors.BTNSECONDARY", newColor)}
                                    />
                                    {formik.touched.colors?.BTNSECONDARY && formik.errors.colors?.BTNSECONDARY && (
                                        <div className='error-msg'>{formik.errors.colors.BTNSECONDARY}</div>
                                    )}
                                </div>

                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Button Secondary Hover"
                                        color={formik.values.colors?.BTNSECONDARYOVER}
                                        onChange={(newColor) => formik.setFieldValue("colors.BTNSECONDARYOVER", newColor)}
                                    />
                                    {formik.touched.colors?.BTNSECONDARYOVER && formik.errors.colors?.BTNSECONDARYOVER && (
                                        <div className='error-msg'>{formik.errors.colors.BTNSECONDARYOVER}</div>
                                    )}
                                </div>
                            <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Input Field"
                                        color={formik.values.colors?.INPUTFIELD}
                                        onChange={(newColor) => formik.setFieldValue("colors.INPUTFIELD", newColor)}
                                    />
                                    {formik.touched.colors?.INPUTFIELD && formik.errors.colors?.INPUTFIELD && (
                                        <div className='error-msg'>{formik.errors.colors.INPUTFIELD}</div>
                                    )}
                                </div>

                                <div className="colorPickers">
                                    <ColorPickerComponent
                                        label="Input Field Border"
                                        color={formik.values.colors?.INPUTFIELDBORDER}
                                        onChange={(newColor) => formik.setFieldValue("colors.INPUTFIELDBORDER", newColor)}
                                    />
                                    {formik.touched.colors?.INPUTFIELDBORDER && formik.errors.colors?.INPUTFIELDBORDER && (
                                        <div className='error-msg'>{formik.errors.colors.INPUTFIELDBORDER}</div>
                                    )}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    {/* Font Base Typography */}
                     <div className="grid grid-nogutter align-items-center mb-4 font-family-section">
                        <div className='col-2'>
                            <label htmlFor="fontBase" className="cmsLabels">Primary Font</label>
                        </div>
                        <div className="col-10">
                            <div className="card">
                                <FontPicker
                                    pickerId="fontBase"
                                    apiKey="AIzaSyA2IcN0idinJogQsEXd2vHrilGhBKB7gvM"
                                    activeFontFamily={formik.values.GEN0006?.fontBase}
                                    previews={true}
                                    onChange={(nextFont) => {
                                        formik.setFieldValue("GEN0006.fontBase", nextFont.family)
                                    }}
                                    limit={200}
                                />
                                {formik.touched.GEN0006?.fontBase && formik.errors.GEN0006?.fontBase ? (
                                    <div className='error-msg'>{formik.errors.GEN0006?.fontBase}</div>
                                ) : null}
                                <span className='apply-font-fontBase ml-5'>Selected font get apply thoughout application</span>
                            </div>
                        </div>
                    </div> 

                    {/* Font Secondary Typography */}
                     <div className="grid grid-nogutter align-items-center mb-4 font-family-section">
                        <div className='col-2'>
                            <label htmlFor="fontSecondary" className="cmsLabels">Secondary Font</label>
                        </div>
                        <div className="col-10">
                            <div className="card">
                                <FontPicker
                                    pickerId="fontSecondary"
                                    apiKey="AIzaSyA2IcN0idinJogQsEXd2vHrilGhBKB7gvM"
                                    activeFontFamily={formik.values.GEN0006?.fontSecondary}
                                    previews={true}
                                    onChange={(nextFont) => {
                                        formik.setFieldValue("GEN0006.fontSecondary", nextFont.family)
                                    }}
                                    limit={200}
                                />
                                {formik.touched.GEN0006?.fontSecondary && formik.errors.GEN0006?.fontSecondary ? (
                                    <div className='error-msg'>{formik.errors.GEN0006?.fontSecondary}</div>
                                ) : null}
                                <span className='apply-font-fontSecondary ml-5'>Selected font get apply thoughout application</span>
                            </div>
                        </div>
                    </div> 

                    {/* Phone */}
                    <div className="grid grid-nogutter align-items-center">
                        <div className='col-2'>
                            <label htmlFor="phone" className="cmsLabels">Phone Number</label>
                        </div>
                        <div className="col-5">
                            <div className="card">
                                <PR.InputText id="GEN0007" name="GEN0007" aria-describedby="phone" className="w-12" value={formik.values.GEN0007} onChange={(e) => handlePhoneChange(e, formik, 'GEN0007')} onBlur={formik.handleBlur} />
                                {formik.errors.GEN0007 && formik.touched.GEN0007
                                    ? <div className='error-message'>{formik.errors.GEN0007}</div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>

                    {/* Email */}
                    <div className="grid grid-nogutter align-items-center mt-4">
                        <div className='col-2'>
                            <label htmlFor="email" className="cmsLabels">Email</label>
                        </div>
                        <div className="col-5">
                            <div className="card">
                                <PR.InputText id="GEN0008" name="GEN0008" aria-describedby="email" className="w-12" value={formik.values.GEN0008} onChange={(e) => handleEmailChange(e, formik, 'GEN0008')} onBlur={formik.handleBlur} />
                                {formik.errors.GEN0008 && formik.touched.GEN0008
                                    ? <div className='error-message'>{formik.errors.GEN0008}</div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-nogutter">
                        <div className="col-2">
                        </div>
                        <div className="col-5">
                            <PR.Button label="Save" type='submit' className="saveBtn" disabled={disableSave} />
                        </div>
                    </div>
                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};
export default General;