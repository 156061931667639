import React, { useRef } from "react";
import * as PR from "../../prime-modules/index";
import { whiteLogo } from "../../assets/images";
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { adminAuthActions } from "../../store/adminAuth";

const AdminHeader = () => {
  const menu = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;



  const items = [
    {
        label: "Logout",
        icon: "pi pi-power-off",
        command : () => {
          dispatch(adminAuthActions.onLogout())
        }
    }
  ];

  const menuItems = [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-th-large',
      command: () => {navigate('/dashboard')},
      className: (pathname === '/dashboard') ? 'active-menu' : ''
    },
    {
      label: 'Orders',
      icon: 'pi pi-fw pi-shopping-cart',
      command: () => {navigate('/orders')},
      className: (pathname.includes('/orders')) ? 'active-menu' : ''
    },
    {
      label: 'Customers',
      icon: 'pi pi-fw pi-users',
      command: () => {navigate('/users')},
      className: (pathname === '/users' || pathname.includes('/user-orders')) ? 'active-menu' : ''
    },
    {
      label: 'Affiliates',
      icon: 'pi pi-sitemap',
      command: () => {navigate('/affiliates')},
      className: (pathname === '/affiliates') ? 'active-menu' : ''
    },
    {
      label: 'Price Adjustment',
      icon: 'pi pi-fw pi-dollar',
      command: () => {navigate('/price-adjustment')},
      className: (pathname === '/price-adjustment') ? 'active-menu' : ''
    },
    {
      label: 'Vouchers',
      icon: 'pi pi-fw pi-percentage custom-font',
      command: () => {navigate('/vouchers')},
      className: (pathname === '/vouchers') ? 'active-menu' : ''
    },
    {
      label: 'Site Maintenance',
      icon: 'pi pi-fw pi-globe',
      command: () => {navigate('/sitemaintenance')},
      className: (pathname === '/sitemaintenance') ? 'active-menu' : ''
    },
    {
      label: 'CMS',
      icon: 'pi pi-fw pi-cog',
      command: () => {navigate('/cms')},
      className: (pathname === '/cms') ? 'active-menu' : ''
    }
  ];

  const end = <div className="flex align-items-center">
    <span className="user-icon"><i className="pi pi-power-off"></i></span>
    <PR.Button
        label="Logout"
        icon="pi pi-arrow-right"
        iconPos="right"
        onClick={() =>  dispatch(adminAuthActions.onLogout())}
    />
    <PR.Menu model={items} popup ref={menu} />
    </div>;

  return (
    <>
      <Helmet>
        <title>data2go: TAKE A BYTE | Backoffice</title>
      </Helmet>
      <section className="admin-header-section">
        <div className="flex align-items-center">
          <Link to="/"><PR.Image src={whiteLogo} alt="Logo" /></Link>
        </div>
      </section>
      <div className="w-12">
        <header className="admin-header">
          <PR.Menubar model={menuItems} end={end} className="w-12" />
        </header>
      </div>
    </>
  )
}

export default AdminHeader