import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import TabsBlock from "./TabsBlock";
import GraphsBlock from "./GraphsBlock";
import "../dashboard/Dashboard.scss"; // CSS
import { getTabs, getGraphs, getMonthGraphs } from "../../services/api";
import moment from "moment";


const Dashboard = () => {
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const navigate = useNavigate();
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const [selectedOrderGraphs, setSelectedOrderGraphs] = useState([]);
  const [selectedRevenueGraphs, setSelectedRevenueGraphs] = useState([]);
  const [selectedMonthGraph, setSelectedMonthGraph] = useState([]);
  const [currentMonthButton, setCurrentMonthButton] = useState(true);
  const [displayedMonth, setDisplayedMonth] = useState("");
  const [date, setDate] = useState(new Date());

  const items = [
    {
      label: "Dashboard",
      command: () => {
        navigate("/dashboard");
      },
    },
  ];

  const home = { icon: "pi pi-home", url: "/" };

  const displayTabs = useCallback(() => {
    const ids = "revenue,bundlesSold";
    getTabs(dispatch, headers, ids, moment(date).format("DD-MM-YYYY"), (response) => {
      if (response.result === "SUCCESS") {
        const statistics = response.data.statistics;
        setSelectedTabs(statistics);
      }
    });
  }, [dispatch, headers, date]);

  const displayGraphs = useCallback(() => {
    const ids = "bundlesSold";
    getGraphs(dispatch, headers, ids,  moment(date).format("DD-MM-YYYY"), (response) => {
      if (response.result === "SUCCESS") {
        setSelectedGraphs(response.data);
      }
    });
  }, [dispatch, headers, date]);

  const displayOrdersGraphs = useCallback(() => {
    const ids = "ordersPerDay";
    getGraphs(dispatch, headers, ids,  moment(date).format("DD-MM-YYYY"), (response) => {
      if (response.result === "SUCCESS") {
        setSelectedOrderGraphs(response.data);
      }
    });
  }, [dispatch, headers, date]);

  const displayRevenueGraphs = useCallback(() => {
    const ids = "revenuePerDay";
    getGraphs(dispatch, headers, ids, moment(date).format("DD-MM-YYYY"), (response) => {
      if (response.result === "SUCCESS") {
        setSelectedRevenueGraphs(response.data);
      }
    });
  }, [dispatch, headers, date]);

  const displayTopBundles = useCallback(() => {
    getMonthGraphs(dispatch, headers, moment(date).format("DD-MM-YYYY"), (response) => {
      if (response.result === "SUCCESS") {
        setSelectedMonthGraph(response.data);
      }
    });
  }, [dispatch, headers, date]);



  useEffect(() => {
    displayTabs();
    displayGraphs();
    displayTopBundles();
    displayOrdersGraphs();
    displayRevenueGraphs();
  }, [
    displayGraphs,
    displayTabs,
    displayTopBundles,
    displayOrdersGraphs,
    displayRevenueGraphs,
  ]);
  const displayMonth = useCallback((val) => {
    let formattedMonth = moment(date).format("MMMM");
    let formattedYear = moment(date).format("YYYY");
    if (!val) {
      formattedMonth = moment(date).subtract(1, "month").format("MMMM");
      formattedYear = moment(date).subtract(1, "month").format("YYYY");
    }
    setDisplayedMonth(`${formattedMonth} ${formattedYear}`);
  }, [date]);
  useEffect(() => {
    displayMonth(currentMonthButton)
  }, [currentMonthButton, displayMonth]);
  const dateChangeHandler = (event) => {
    setDate(event.value); // Ensure you access the `value` property of the event
  };


  return (
    <>
      <div className="main">
        <div className="layout-sidebar">
          <AdminHeader />
        </div>
        <div className="layout-content-wrapper">
          <section className="admin-users-section">
            <div className="grid grid-nogutter align-items-center">
              <div className="col-12">
                <div className="heading-sec flex align-items-center justify-content-between">
                  <div>
                    <div className="flex align-items-center">
                      <div className="card flex align-items-center">
                        <h1>Dashboard</h1>{" "}
                      </div>
                      <div className="breadcrumb-section">
                        <PR.BreadCrumb model={items} home={home} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard_section">
              <p>
                {`Showing data for the month of`}{" "}
                <strong>{displayedMonth}</strong>
              </p>
            </div>
            <div className="dashboard_section">
              <TabsBlock
                selectedTabs={selectedTabs}
                currentMonthButton={currentMonthButton}
              />
              <div className="flex gap-3 current-previous-btns-wrapper">
                <PR.Calendar
                  value={date}
                  onChange={dateChangeHandler}
                  selectionMode="single"
                  readOnlyInput
                  placeholder="Select Month/Year"
                  name="date"
                  dateFormat="MM-yy"
                  showIcon
                  showButtonBar
                  numberOfMonths={2}
                  onClearButtonClick={() => setDate(new Date())}
                  view="month"
                  className="month-year-calendar"
                />
                <div className={currentMonthButton ? "active" : ""}>
                  <PR.Button
                    onClick={()=>setCurrentMonthButton(true)}
                    className="current-previous-buttons"
                  >
                     {moment(date).format('MMMM')} {moment(date).format('YYYY')}
                  </PR.Button>
                </div>
                <div className={!currentMonthButton ? "active" : ""}>
                  <PR.Button
                    onClick={()=>setCurrentMonthButton(false)}
                    className="current-previous-buttons"
                  >
                    {moment(date).subtract(1, 'month').format('MMMM')} {moment(date).subtract(1, 'month').format('YYYY')}
                  </PR.Button>
                </div>
              </div>
              <GraphsBlock
                selectedGraphs={selectedGraphs}
                selectedMonthGraph={selectedMonthGraph}
                selectedOrderGraphs={selectedOrderGraphs}
                selectedRevenueGraphs={selectedRevenueGraphs}
                currentMonthButton={currentMonthButton}
              />
            </div>
          </section>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
