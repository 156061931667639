import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { useSelector, useDispatch } from 'react-redux';
import { getPromoCodesList, modifyPromoCode } from "../../services/api.jsx";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import "../promo-code/PromoCode.scss";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { parseInt } from "lodash";

const PromoCode = () => {
    const dispatch = useDispatch();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const toast = useRef();
    const [visible, setVisible] = useState(false);
    const [updateData, setUpdateData] = useState("")
    const [loading, setLoading] = useState(false);
    const [promoCodes, setPromoCodes] = useState([]);
    const [sortField, setSortField] = useState("status");
    const [sortOrder, setSortOrder] = useState(1);
    const [promoCodeError, setPromoCodeError] = useState("");
    const [maxUserError, setMaxUserError] = useState("")
    const [maxUserCheck, setMaxUserCheck] = useState(false)
    const [filterValues, setFilterValues] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [checked, setChecked] = useState(false);
    const initialFilters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'promoCode': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        'percentage': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    }
    const [filters, setFilters] = useState(initialFilters)
    const statusDropdownVals = [
        { label: "ACTIVE", value: "ACTIVE" },
        { label: "PENDING", value: "PENDING" },
        { label: "EXPIRED", value: "EXPIRED" },
        { label: "USED", value: "USED" }
    ]
    const currentDate = new Date()
    const modifyFilterValuesRequest = values => {
        let status = values.status.length > 0 ? values.status.join(',') : '';
        let dates = values.date.length > 0 && values.date.filter(d => d !== null);
        let startTs = dates.length === 2 ? utils.formatDate(dates[0]) : dates.length === 1 ? utils.formatDate(dates[0]) : '';
        let endTs = dates.length === 2 ? utils.formatDate(dates[1]) : dates.length === 1 ? utils.formatDate(dates[0]) : '';
        return { startTs, endTs, status };
    }

    const searchFormik = useFormik({
        initialValues: {
            date: "",
            status: ""
        },
        onSubmit: values => setFilterValues(values) 
    })

    const getPromoCodes = useCallback(async (request = { startTs: '', endTs: '', status: '' }) => {

        setLoading(true);
        let getResponse = response => {
            if (response.result === "SUCCESS") {
                setLoading(false);
                const promoCodesList = response.data ? response.data : [];
                if (promoCodesList.length > 0) {
                    promoCodesList.map(val => {
                        val.formattedStartTs = moment(val.startTs).format("DD-MM-YYYY, HH:mm:ss");
                        val.formattedEndTs = moment(val.endTs).format("DD-MM-YYYY, HH:mm:ss");
                        val.formattedAllowedUse = val.maxNrOfUses
                        val.calendarStartTs = utils.formatCalendarDate(val.startTs);
                        val.calendarEndTs = utils.formatCalendarDate(val.endTs);   
                        setChecked(val.maxNrOfUses === -1);  
                        return val
                    })
                } else {
                    setLoading(false);
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No records found' });
                }
                setPromoCodes(promoCodesList);
            } else {
                setLoading(false);
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        }
        const requestObj = filterValues ? modifyFilterValuesRequest(filterValues) : request
        getPromoCodesList(requestObj, headers, dispatch, getResponse)
    }, [dispatch, filterValues, headers])

    const initialValues = {
        id: updateData ? updateData.id : '',
        voucherCount : "",
        promoCode: updateData ? updateData.promoCode : '',
        discount: updateData ? updateData.percentage : '',
        startTs: updateData ? updateData.calendarStartTs: '',
        endTs: updateData ? updateData.calendarEndTs: '',
        maxNrOfUses: updateData?.maxNrOfUses === -1 ? '' : updateData.maxNrOfUses ? updateData?.maxNrOfUses : '1',
    }

    const validationSchema = () => {
        const discountRegex = "^([1-9]|[1-9][0-9]|100)$";
        return Yup.object().shape({
            promoCode: Yup.string().required('Voucher Code is required'),
            discount: Yup.string().trim()
                .required('Discount is required')
                .matches(discountRegex, "Discount should be between 1 to 100"),
            startTs: Yup.string().trim()
                .required('Start Date is required')
                .nullable(),
            endTs: Yup.string().trim()
                .required('End Date is required')
                .nullable(),
        });
    }

    const handleSubmit = values => {
        let currentDate = (d) => new Date(d)
        if (currentDate(values.startTs) > currentDate(values.endTs)) {
            setPromoCodeError("Start Date should be less than End Date")
            return null
        } else {
            const getResponse = response => {
                const summary = updateData ? 'updated' : 'added';
                if (response.result === "SUCCESS") {
                    setPromoCodeError("")
                    setLoading(false);
                    setVisible(false);
                    getPromoCodes();
                    formik.resetForm();
                    toast.current.show({ severity: 'success', summary: `Voucher details ${summary} successfully` });
                } else {
                    setLoading(false);
                    setPromoCodeError("")
                    const error = response.error;
                    setPromoCodeError(error.errorMsg ? error.errorMsg : error.summary)
                }
            }
            const addRequestObj = {
                promoCode: values.promoCode,
                voucherCount: values.voucherCount ? +values.voucherCount : undefined ,
                percentage: +values.discount,
                startTs: moment.utc(values.startTs).format(),
                endTs: moment.utc(values.endTs).format(),
                maxNrOfUses: checked ? -1 : +values.maxNrOfUses
            }
            const updateRequestObj = {
                id: +values.id,
                promoCode: values.promoCode,
                percentage: +values.discount,
                startTs: moment.utc(values.startTs).format(),
                endTs: moment.utc(values.endTs).format(),
                maxNrOfUses: checked ? -1 : +values.maxNrOfUses
            }
            const requestObj = updateData ? updateRequestObj : addRequestObj;
            modifyPromoCode(requestObj, headers, dispatch, getResponse);
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true
    })
    
    const status = (rowData) => {
        let status = rowData.status === "ACTIVE"
            ? <span className="status completed">ACTIVE</span>
            : rowData.status === "PENDING"
                ? <span className="status pending">PENDING</span>
                : rowData.status === "EXPIRED"
                    ? <span className="status failed">EXPIRED</span>
                    : rowData.status === "USED"
                        ? <span className="status used">USED</span>
                        : "-"
        return status
    };

    const setUnlimitedCheck = (rowData) => {
        setChecked(rowData.maxNrOfUses === -1)  
    }

    const actions = (rowData) => {
        return (
            <>
                <PR.Button
                    icon="pi pi-pencil"
                    className="action-btn edit"
                    title="Edit"
                    onClick={() => {
                        setVisible(true)
                        setUnlimitedCheck(rowData);
                        setUpdateData(rowData)
                        setPromoCodeError("")
                    }}
                />
            </>
        );
    };

    useEffect(() => {
        getPromoCodes();
    }, [getPromoCodes]);

    const renderDiscount = (rowData) =>  rowData.percentage + ' %'
    const formattedStartTs = (rowData) => rowData.formattedStartTs
    const formattedEndTs = (rowData) => rowData.formattedEndTs
    const formattedAllowedUse = (rowData) =>  rowData.formattedAllowedUse === -1 ? 'Unlimited' : rowData.formattedAllowedUse;

    const gloabalSearchHandler = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
 
        setFilters(_filters);
        setSearchValue(value);
    }

    const voucherCountHandler = e => {
        let word = e.target.value.substring(0,4);
        if(/^0*$/.test(word)) {
            word = ""
        } else if (word > 1000) {
            word = 1000
        }
        let value = parseInt(word, 10).toString();
        formik.setFieldValue('voucherCount', value !== "NaN" ? value : "");
        setPromoCodeError("");
    }

    const promoCodeHandler = e => {
        let cleaned =  (formik.values.voucherCount > 1 && e.target.value > 15) ? e.target.value.replace(/[^0-9]/g, '').substring(0, 15) : e.target.value;
        formik.setFieldValue('promoCode', cleaned)
        setPromoCodeError("");
    }

    const discountHandler = (e) => {
        let word = e.target.value.substring(0,3);
        if(/^0*$/.test(word)) {
            word = ""
        } else if (word > 100) {
            word = 100
        }
        let value = parseInt(word, 10).toString();
        formik.setFieldValue('discount', value !== "NaN" ? value : "");
        setPromoCodeError("");
    }
    const dateHandler = e => {
        setPromoCodeError("");
        formik.handleChange(e);
    }

    const maxUsesHandler = e => {
        const maxUses = e.target.value;
        if (maxUses === '0'|| maxUses.toString().startsWith('0')) {
            formik.setFieldValue("maxNrOfUses", '1');
        } else {
            formik.setFieldValue("maxNrOfUses", maxUses);
        }
    }

    useEffect(() => {
        if(!checked && formik.values.maxNrOfUses === ""){
            setMaxUserError('Max no.of Uses is required')
            setMaxUserCheck(true)
        } else {
            setMaxUserError("")
            setMaxUserCheck(false)
        }
    },[checked, formik.values.maxNrOfUses])

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-users-section promo-code-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="mb-5">
                                        <h1>Vouchers</h1>
                                    </div>
                                    <div className="flex align-items-center justify-content-between filter-right">
                                            <div className="flex">
                                                <span className="p-input-icon-right search-field">
                                                    <i className="pi pi-search" />
                                                    <PR.InputText placeholder="Search" type="search" value={searchValue} onChange={gloabalSearchHandler} />
                                                </span>
                                                <form onSubmit={searchFormik.handleSubmit}>
                                                    <PR.Calendar
                                                        value={searchFormik.values.date}
                                                        onChange={searchFormik.handleChange}
                                                        selectionMode="range"
                                                        readOnlyInput
                                                        placeholder="Vouchers date range"
                                                        name="date"
                                                        dateFormat="dd-mm-yy"
                                                        showIcon
                                                        showButtonBar
                                                        numberOfMonths={2}
                                                        onClearButtonClick={() => searchFormik.setFieldValue("date", [])}
                                                    />
                                                    <PR.MultiSelect
                                                        placeholder="Status"
                                                        options={statusDropdownVals}
                                                        optionLabel="label"
                                                        optionValue="value"
                                                        name="status"
                                                        value={searchFormik.values.status}
                                                        onChange={searchFormik.handleChange}
                                                        maxSelectedLabels={1}
                                                    />
                                                    <PR.Button type="submit" label="Search" className="searchBtn" />
                                                    <PR.Button type="reset" label="Reset" className="resetBtn" onClick={() => {
                                                        searchFormik.handleReset();
                                                        setFilterValues("");
                                                        setSearchValue("");
                                                        setFilters(initialFilters);
                                                    }} />
                                                </form>
                                            </div>
                                            <PR.Button label="Add New Voucher" className="add-button" onClick={() => { setVisible(true); setUpdateData(""); setPromoCodeError(""); }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table card">
                            <PR.DataTable
                                loading={loading}
                                value={promoCodes}
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                paginatorDropdownAppendTo={"self"}
                                rows={50}
                                let-i="rowIndex"
                                filters={filters}
                                globalFilterFields={['promoCode', 'percentage']}
                                rowsPerPageOptions={[10, 20, 50]}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={(e) => {
                                    setSortField(e.sortField);
                                    setSortOrder(e.sortOrder);
                                }}>
                                <PR.Column field="promoCode" header="Vouchers" sortable></PR.Column>
                                <PR.Column field="percentage" header="Discount" body={renderDiscount} sortable></PR.Column>
                                <PR.Column field="startTs" header="Start date" body={formattedStartTs} sortable></PR.Column>
                                <PR.Column field="endTs" header="End date" body={formattedEndTs} sortable></PR.Column>
                                <PR.Column field="maxNrOfUses" header="Allowed Use" body={formattedAllowedUse} sortable></PR.Column>
                                <PR.Column field="nrOfUses" header="Usage Count" sortable></PR.Column>
                                <PR.Column header="Status" field="status" body={status} sortable></PR.Column>
                                <PR.Column body={actions} header="Actions"></PR.Column>
                            </PR.DataTable>
                        </div>
                    </section>
                    <PR.Dialog header={(updateData ? "Update" : "Add") + " Voucher"} visible={visible} draggable={false} blockScroll onHide={() => { setVisible(false); formik.resetForm(); }} className="add-promo-code-dialog">
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="p-float-label-none hidden">
                                <span>
                                    <PR.InputText placeholder="id" name="id" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.id} className="w-full" />
                                </span>
                            </div>
                            {
                                !updateData &&
                                <div className="p-float-label-none">
                                    <span>
                                        <PR.InputText placeholder="Voucher Count (Optional)" name="voucherCount" onChange={voucherCountHandler} value={formik.values.voucherCount} className="w-full" keyfilter={"pint"} min={1} maxLength={4} />
                                    </span>
                            </div>
                            }
                            <div className="p-float-label-none">
                                <span>
                                    <PR.InputText placeholder="Voucher Code" name="promoCode" onChange={promoCodeHandler} onBlur={formik.handleBlur} value={formik.values.promoCode} className="w-full" keyfilter="alphanum" maxLength={20} />
                                </span>
                                {formik.errors.promoCode && formik.touched.promoCode
                                    ? <div className='error-message'>{formik.errors.promoCode}</div>
                                    : ''
                                }
                            </div>

                            <div className="p-float-label-none">
                                <span className="p-input-icon-right w-full">
                                    <i className="pi pi-percentage" />
                                    <PR.InputText placeholder="Discount" name="discount" onChange={discountHandler} onBlur={formik.handleBlur} value={formik.values.discount} className="w-full" min={1}/>
                                </span>
                                {formik.errors.discount && formik.touched.discount
                                    ? <div className='error-message'>{formik.errors.discount}</div>
                                    : ''
                                }
                            </div>
                            <span className="p-float-label-none custom-margin">
                                <PR.Calendar placeholder="Start Date" name="startTs" value={formik.values.startTs} onChange={dateHandler} onBlur={formik.handleBlur} className="w-full voucher-calendar" dateFormat="dd-mm-yy" showIcon showButtonBar hideOnDateTimeSelect hourFormat="12" minDate={currentDate}  showTime showSeconds readOnlyInput/>
                            </span>
                            {formik.errors.startTs && formik.touched.startTs
                                ? <div className='error-message'>{formik.errors.startTs}</div>
                                : ''
                            }
                            <span className="p-float-label-none custom-margin">
                                <PR.Calendar placeholder="End Date" id="end-date" name="endTs" value={formik.values.endTs} onChange={dateHandler} onBlur={formik.handleBlur} className="w-full voucher-calendar" dateFormat="dd-mm-yy" showIcon showButtonBar hideOnDateTimeSelect hourFormat="12" minDate={currentDate} showTime showSeconds readOnlyInput/>
                            </span>
                            {formik.errors.endTs && formik.touched.endTs
                                ? <div className='error-message'>{formik.errors.endTs}</div>
                                : ""
                            }
                        {!checked && <div className="p-float-label-none">
                            <span>
                                <PR.InputText placeholder="Max no.of Uses" name="maxNrOfUses" onChange={maxUsesHandler} onBlur={formik.handleBlur} value={formik.values.maxNrOfUses} className="w-full" keyfilter="pint" title="Max no.of uses"/>
                            </span>
                                <div className='error-message'>{maxUserError}</div>
                        </div>}

                        <div className="mt-3">
                            <PR.Checkbox checked={checked} onChange={e => setChecked(e.checked)}  />
                            <label className="confirmationText ml-2">Unlimited</label>
                        </div>

                        {promoCodeError && <div className='error-message'>{promoCodeError}</div>}
                        <PR.Button label={updateData ? "UPDATE" : "ADD"} type='submit' className="submitBtn" disabled={!formik.isValid || promoCodeError || maxUserCheck} />
                    </form>
                    </PR.Dialog>
                    <PR.Toast ref={toast} position='top-right' />
                    <AdminFooter />
                </div>
            </div>
        </>
    );

};

export default PromoCode;