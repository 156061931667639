import React from 'react';
import { useState,useMemo } from 'react';
import * as PR from "../../../prime-modules/index";
import { useFormik } from 'formik';
import { uploadAssetsApi } from "../../../services/api"
import { useDispatch, useSelector } from 'react-redux';
import "./FAQ.scss";
import { trimFormData } from '../../../utils';
import { getAllAssets } from '../../../genericFunctions/getAssets';
import ImagePreview from '../generic/imagePreview';
import { uploadWidthHeightImage } from '../../../utils/reuse';

const FAQ = ({assets, disableSave}) => {
    const dispatch = useDispatch();
    const toast = React.useRef(null)
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const [faqFeature, setFaqFeature] = useState(null)

    const { FAQ0001 , FAQ0002 } = assets ? assets : {}

    const handleSubmit = async (data) => {  
        const formData = new FormData();
        const trimmedFormData = trimFormData(data);
        formData.append('screen', 'faq');
        for (const field in trimmedFormData) {
            formData.append(field, trimmedFormData[field]);
        }
        const getCMSResponse = (response) => {
            if (response.result === 'SUCCESS') {
                if(response.data === 'success'){
                  toast.current.show({ severity: 'success', summary: 'Success', detail: ' Updated successfully' });
                }
                getAllAssets(toast, dispatch, headers);
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        };
        uploadAssetsApi(formData, headers, dispatch, getCMSResponse);
    }
    
     const formik = useFormik({
        initialValues: {
            FAQ0001,
            FAQ0002
        },
        onSubmit: handleSubmit,
        enableReinitialize: true, 
    })


    return (
        <div className='general faq-section'>
            <div className="card">
                {/* <h2>FAQ Settings</h2> */}
                <form autoComplete="off" onSubmit={formik.handleSubmit} >
                    <div className="imageFormat mb-4 ">
                        <strong>(Image Size Should not exceed 1MB)</strong>
                    </div>
                    <div className="grid grid-nogutter align-items-center mb-4 mt-4">
                        <div className='col-4'>
                            <label htmlFor="FAQ0001" className="cmsLabels">Feature Image <span className='imageFormat'>(png, jpeg, 1040W X 941H)</span></label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    type="file"
                                    name="FAQ0001"
                                    className="file-upload-input"
                                    accept="image/png, image/jpeg"
                                    onChange={async(event) => {
                                        const file = event.currentTarget.files[0];
                                        setFaqFeature(file);
                                        uploadWidthHeightImage(file, "FAQ0001", toast, dispatch, headers, 'faq')
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {faqFeature && formik.touched.FAQ0001 && formik.errors.FAQ0001 ? (
                                    <div className='error-msg'>{formik.errors.FAQ0001}</div>
                                ) : null}
                                 <ImagePreview formikError={formik.errors.FAQ0001} defaultImage={FAQ0001} newImage={faqFeature}/>
                            </div>
                        </div>
                    </div>

                    {/* <div className="grid grid-nogutter align-items-center">
                        <div className='col-4'>
                            <label htmlFor="FAQ0002" className="cmsLabels">JSON Data <br/><span className='imageFormat'>(Download the acceptable sample json file <a href="/FAQ0002.json" download="FAQ0002">here</a> and upload it after modifications)</span></label>
                        </div>
                        <div className="col-5">
                            <div className="file-upload-section">
                                <PR.InputText
                                    type="file"
                                    name="FAQ0002"
                                    className="file-upload-input"
                                    accept="application/JSON"
                                    onChange={(event) => {
                                        const file = event.currentTarget.files[0];
                                        setFaqJsonData(file);
                                        formik.setFieldValue("FAQ0002", file);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.FAQ0002 && formik.errors.FAQ0002 ? (
                                    <div className='error-msg'>{formik.errors.FAQ0002}</div>
                                ) : null}
                            </div>
                        </div>
                    </div> */}

                    <div className="grid grid-nogutter">
                        <div className="col-12">
                            <PR.Button label="Save" type='submit' className="saveBtn" disabled={disableSave}/>
                        </div>
                    </div>

                </form>
            </div>
            <PR.Toast ref={toast} position="top-right" />
        </div>
    );
};
export default FAQ;